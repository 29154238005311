import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import md5 from 'md5';
import { Form, Button, Card, Modal, Collapse, Alert } from 'react-bootstrap';
import s3 from '../../../util/s3';
import api from '../../../services/api';
import header from '../../../assets/icm-header.jpg';
import blank from '../../../assets/icm-blank.jpg';
import styles from '../../../styles/styles.module.css';

export default function ReportCongTXT() {
  const { eco } = useSelector(state => state.eco);
  const medico = useSelector(state => state.doctor.profile);
  const { printing } = useSelector(state => state.contexto);
  const { funcionario } = useSelector(state => state.funcionario);
  const [showModal, setShowModal] = useState(false);
  const [urls, setURLs] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(null);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = React.useState({});
  const isAllowedToDeleteFiles =
    medico ||
    funcionario?.codFunc === '90035' ||
    funcionario?.codFunc === '90026' ||
    funcionario?.codFunc === '90001';

  useEffect(() => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `eco/${eco.CODECO}` },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else
          setFiles(
            data.Contents.filter(
              item =>
                item.Key.includes('.webm') ||
                item.Key.includes('.pdf') ||
                item.Key.includes('.mp4')
            )
          );
      }
    );
  }, [eco.CODECO]);

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    document.getElementById('btnFonte').style.visibility = 'hidden';
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
    document.getElementById('btnFonte').style.visibility = 'visible';
  }

  function handleDescricao() {
    return <div className={styles.preContent}>{eco.OBSERVACOES}</div>;
  }

  function alteraFont(e) {
    let elemento = document.getElementById('ecoObs');
    let fonte = parseInt(elemento.style.fontSize);

    if (e === 'a') fonte++;
    if (e === 'd') fonte--;
    elemento.style.fontSize = fonte + 'px';
  }

  async function getFiles(f) {
    f = f.Key.split('/');

    return await api.get(
      `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/eco/laudo/${eco?.CODECO}/key/${f[2]}`
    );
  }

  async function showFiles() {
    setShowModal(true);

    const array = [];

    for (let file of files) {
      const url = await getFiles(file);
      const obj = { url, ...file }; // coloco num obj só a presigned URL e o file, pq preciso da Key para deletar depois
      array.push(obj);
    }

    setURLs(array);
  }

  function prefixo() {
    if (
      eco.CODREALIZOU === 1 ||
      eco.CODREALIZOU === 5 ||
      eco.CODREALIZOU === 2796 ||
      eco.CODREALIZOU === 958
    ) {
      return 'Prof. Dr. ';
    } else {
      return 'Dr. ';
    }
  }

  function handleClick(id) {
    setOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
  }

  async function deleteS3Object(file) {
    try {
      await s3.deleteObject({ Bucket: 'prevencor', Key: file }).promise();
      toast.success('O arquivo foi deletado com sucesso!');
      setOpenDeleteAlert(null);
      setShowModal(false);
    } catch (error) {
      toast.error('Erro ao deletar arquivo. Entre em contato.');
    }
  }

  return (
    <>
      <div>
        {printing ? (
          <div className={styles.imageReport}>
            <img width="550" height="100" src={header} alt="header" />
          </div>
        ) : (
          <div className={styles.imageReport}>
            <img width="550" height="100" src={blank} alt="header" />
          </div>
        )}
        <div id="btnFonte" className="d-flex justify-content-end">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => alteraFont('a')}
          >
            A+
          </Button>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => alteraFont('d')}
          >
            A-
          </Button>
        </div>
        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            LAUDO DE ECOCARDIOGRAMA CONGÊNITO
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {eco && eco.TIPOLAUDO === 'Alterado' ? (
            <Form noValidate className={styles.contentReport}>
              <strong className="mr-auto">Nome do Paciente: </strong>
              {`${eco.CODPAC} - ${eco.NOMEPAC}`}
              <br></br>
              <strong className="mr-auto">Data de Nasc: </strong>
              {`${eco.dataNascFmt}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Sexo: </strong>
              {`${eco.Sexo}`}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Idade: </strong>
              {`${eco.idadePAC} anos`}
              <br></br>
              <strong className="mr-auto">Convênio: </strong>
              {eco.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Cod Eco: </strong>
              {eco.CODECO}&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Data do Exame: </strong>
              {eco.dataEcoFmt}
              <br></br>
              <strong className="mr-auto">
                Médico que Realizou: {prefixo()}{' '}
              </strong>
              {`${eco.Realizou}`}
              <br></br>
              <strong className="mr-auto">
                Médico que Solicitou: {prefixo()}{' '}
              </strong>
              {`${eco.Solicitou}`}
              <hr></hr>
              <strong className="mr-auto"></strong>
              <div className="row">
                <div id="ecoObs" style={{ fontSize: 17 }} className="col-12">
                  {handleDescricao()}
                </div>
              </div>
              <Card className="mt-3 text-center">
                <Card.Body>
                  {files.length > 0 && (
                    <div className={styles.messageReport}>
                      Imagens disponíveis no site:{' '}
                      <strong>www.icm.com.br (Resultado de Exames)</strong>
                      <hr></hr>
                    </div>
                  )}

                  <div className={styles.signatureReport}>
                    <i className="mr-auto">
                      Conferido e assinado eletronicamente por:{' '}
                    </i>
                    <strong className="mr-auto">
                      {' '}
                      {prefixo()} {eco.Realizou}
                    </strong>
                    <br></br>
                    <i className="mr-auto">
                      Assinatura Digital: {md5(JSON.stringify(eco))}
                    </i>
                  </div>
                </Card.Body>
                <Card.Footer id="removeMe" className="text-muted">
                  <Button onClick={() => removeDiv('removeMe')}>
                    Imprimir
                  </Button>{' '}
                </Card.Footer>
              </Card>
            </Form>
          ) : (
            <Form noValidate className={styles.contentReport}>
              <strong className="mr-auto">Nome do Paciente: </strong>
              {`${eco.CODPAC} - ${eco.NOMEPAC}`}
              <br></br>
              <strong className="mr-auto">Data de Nasc: </strong>
              {`${eco.dataNascFmt}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Sexo: </strong>
              {`${eco.Sexo}`}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Idade: </strong>
              {`${eco.idadePAC} anos`}
              <br></br>
              <strong className="mr-auto">Convênio: </strong>
              {eco.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Cod Laudo: </strong>
              {eco.CODECO}&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Data do Exame: </strong>
              {eco.dataEcoFmt}
              <br></br>
              <strong className="mr-auto">
                Médico que Realizou: {prefixo()}{' '}
              </strong>
              {`${eco.Realizou}`}
              <br></br>
              <strong className="mr-auto">
                Médico que Solicitou: {prefixo()}{' '}
              </strong>
              {`${eco.Solicitou}`}
              <hr></hr>
              <hr></hr>
              <strong className="mr-auto"></strong>
              <div className="row">
                <div id="ecoObs" style={{ fontSize: 18 }} className="col-12">
                  {handleDescricao()}
                </div>
              </div>
              <Card className="mt-3 text-center">
                <Card.Body>
                  {files.length > 0 && (
                    <div className={styles.messageReport}>
                      Imagens disponíveis no site:{' '}
                      <strong>www.icm.com.br (Resultado de Exames)</strong>
                      <hr></hr>
                    </div>
                  )}
                  <div className={styles.signatureReport}>
                    <br></br>
                    <i className="mr-auto">
                      Conferido e assinado eletronicamente por:{' '}
                    </i>
                    <strong className="mr-auto">
                      {prefixo()} {eco.Realizou}
                    </strong>
                    <br></br>
                    <i className="mr-auto">
                      Assinatura Digital: {md5(JSON.stringify(eco))}
                    </i>
                  </div>
                </Card.Body>
                <Card.Footer id="removeMe" className="text-muted">
                  <Button onClick={() => removeDiv('removeMe')}>
                    Imprimir
                  </Button>{' '}
                  <Button variant="success" onClick={() => showFiles()}>
                    Ver Imagem
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Card.Body>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Visualização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {urls.map((url, idx) => {
            if (isAllowedToDeleteFiles) {
              return (
                <div>
                  <Card>
                    <Alert
                      show={openDeleteAlert === url.Key}
                      variant="danger"
                      onClose={() => setOpenDeleteAlert(null)}
                      dismissible
                    >
                      <Alert.Heading>
                        Você tem certeza que quer deletar a imagem?
                      </Alert.Heading>
                      <hr />
                      <div className="d-flex justify-content-">
                        <Button
                          onClick={() => deleteS3Object(url.Key)}
                          variant="danger"
                        >
                          Confirmar
                        </Button>
                      </div>
                    </Alert>
                    <div class="btn-group">
                      <button
                        class="btn btn-primary"
                        onClick={() => handleClick(url.url.data.preSignedUrl)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ color: 'white' }}
                      >
                        Imagem {idx + 1}
                      </button>
                      <button
                        class="btn btn-danger"
                        onClick={() => setOpenDeleteAlert(url.Key)}
                        style={{ color: 'white' }}
                      >
                        Deletar
                      </button>
                    </div>
                  </Card>
                  <Collapse in={open[url.url.data.preSignedUrl]}>
                    <div
                      key={url.url.data.preSignedUrl}
                      id="collapse-div"
                      className="collapsedText"
                    >
                      {url.url.data.preSignedUrl.includes('.webm') ||
                      url.url.data.preSignedUrl.includes('.mp4') ? (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <video
                            id="video-laudo"
                            width="500"
                            height="500"
                            controls
                          >
                            <source
                              src={url.url.data.preSignedUrl}
                              type={
                                url.url.data.preSignedUrl.endsWith('.mp4')
                                  ? 'video/mp4'
                                  : 'video/webm'
                              }
                            />
                          </video>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <embed
                            src={url.url.data.preSignedUrl}
                            width="600px"
                            height="900px"
                          />
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
              );
            } else {
              return (
                <div>
                  <Card>
                    <div class="btn-group">
                      <button
                        class="btn btn-primary"
                        onClick={() => handleClick(url.url.data.preSignedUrl)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ color: 'white' }}
                      >
                        Imagem {idx + 1}
                      </button>
                    </div>
                  </Card>
                  <Collapse in={open[url.url.data.preSignedUrl]}>
                    <div
                      key={url.url.data.preSignedUrl}
                      id="collapse-div"
                      className="collapsedText"
                    >
                      {url.url.data.preSignedUrl.includes('.webm') ||
                      url.url.data.preSignedUrl.includes('.mp4') ? (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <video
                            id="video-laudo"
                            width="500"
                            height="500"
                            controls
                          >
                            <source
                              src={url.url.data.preSignedUrl}
                              type={
                                url.url.data.preSignedUrl.endsWith('.mp4')
                                  ? 'video/mp4'
                                  : 'video/webm'
                              }
                            />
                          </video>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <embed
                            src={url.url.data.preSignedUrl}
                            width="600px"
                            height="900px"
                          />
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
              );
            }
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}
