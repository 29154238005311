import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Nav, Navbar, Modal } from 'react-bootstrap';
import logo from '../../../assets/logo.png';
import { signOutMed } from '../../../store/modules/auth/doctor/actions';
import { signOutFunc } from '../../../store/modules/auth/funcionario/actions';
import styles from '../../../styles/styles.module.css';
import history from '../../../services/history';

export default function NavBar() {
  const { profile: medico } = useSelector(state => state.doctor);
  const { funcionario } = useSelector(state => state.funcionario);
  const dispatch = useDispatch();
  const [SSEConnection, setSSEConnection] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!SSEConnection) {
      const baseUrl = process.env.REACT_APP_API_URL;
      const eventsUrl = new URL('/events', baseUrl).href;
      const events = new EventSource(eventsUrl);

      events.onmessage = event => {
        const parsedData = JSON.parse(event.data);
        setNotifications(notifications => notifications.concat(parsedData));
        toast.info('Você tem uma nova notificação!');
      };

      setSSEConnection(true);
    }
  }, [SSEConnection]);

  const sortedNotifications = notifications.sort((a, b) => {
    const timeA = new Date(`1970-01-01T${a.time}:00`);
    const timeB = new Date(`1970-01-01T${b.time}:00`);
    return timeB - timeA;
  });

  const handleClickAutenticar = event => {
    event.preventDefault();
    window.location.assign(
      'https://certificado.vidaas.com.br/v0/oauth/authorize?lifetime=43200&scope=signature_session&client_id=ab46d834-4f16-4b89-b1a9-f231737513ad&code_challenge=E9Melhoa2OwvFrEMTJguCHaoeK1t8URWbuGJSstw-cM&code_challenge_method=S256&response_type=code&redirect_uri=http://icm-marilia.com/tokenizer'
    );
  };

  return (
    <>
      {medico && medico.tipo === 'Externo' ? (
        <Navbar bg="success" expand="lg" className={styles.navbarLink}>
          <Navbar.Brand onClick={() => history.push('/frmhome')}>
            <img src={logo} alt="logo" width={50} height={50} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link onClick={() => history.push('/frmatv')}>
                Exames
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  dispatch(signOutMed());
                  dispatch(signOutFunc());
                }}
              >
                Sair
              </Nav.Link>
            </Nav>
            <Navbar.Text>
              Usuário:{' '}
              {medico
                ? `${medico.apelido} - ${medico.valida}`
                : new Date().toDateString()}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      ) : (
        <Navbar bg="success" expand="lg" className={styles.navbarLink}>
          <Navbar.Brand onClick={() => history.push('/frmhome')}>
            <img src={logo} alt="logo" width={50} height={50} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link onClick={() => history.push('/agenda')}>
                Agenda
              </Nav.Link>
              <Nav.Link onClick={() => history.push('/frmatv')}>
                Exames
              </Nav.Link>
              <Nav.Link onClick={e => handleClickAutenticar(e)}>
                Autenticar
              </Nav.Link>
              <Nav.Link onClick={() => history.push('/frmmedico')}>
                Médicos
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  dispatch(signOutMed());
                  dispatch(signOutFunc());
                }}
              >
                Sair
              </Nav.Link>
            </Nav>
            <Navbar.Text>
              {medico && (
                <button
                  type="button"
                  class="btn btn-danger btn-sm mr-2"
                  onClick={() => setShowModal(true)}
                >
                  <span class="badge badge-light">{notifications.length}</span>
                </button>
              )}
              Usuário: {medico ? medico?.apelido : funcionario?.nome}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notificações</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sortedNotifications.map(notification => (
            <div
              key={notification.time}
              style={{ padding: 10, borderBottom: '1px solid #ddd' }}
            >
              <p>
                <strong>{notification.time}</strong> - {notification.message}
              </p>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
}
