/* eslint-disable no-console */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { createEditor, Editor, Transforms } from 'slate';
import { FaBold, FaItalic, FaUnderline } from 'react-icons/fa';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { serialize, deserialize, extractText } from '../util/slateEditor';
import {
  loadingClose,
  loadingOpen,
} from '../redux/features/context/contextSlice';
import Loading from '../pages/Index/Loading';

// const LIST_TYPES = ['numbered-list', 'bulleted-list'];
// const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={{ margin: 0, padding: 0 }} {...attributes}>
          {children}
        </p>
      );
  }
};

// const toggleBlock = (editor, format) => {
//   const isActive = isBlockActive(
//     editor,
//     format,
//     TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
//   );
//   const isList = LIST_TYPES.includes(format);

//   Transforms.unwrapNodes(editor, {
//     match: n =>
//       !Editor.isEditor(n) &&
//       SlateElement.isElement(n) &&
//       LIST_TYPES.includes(n.type) &&
//       !TEXT_ALIGN_TYPES.includes(format),
//     split: true,
//   });
//   let newProperties;
//   if (TEXT_ALIGN_TYPES.includes(format)) {
//     newProperties = {
//       align: isActive ? undefined : format,
//     };
//   } else {
//     newProperties = {
//       type: isActive ? 'paragraph' : isList ? 'list-item' : format,
//     };
//   }
//   Transforms.setNodes(editor, newProperties);

//   if (!isActive && isList) {
//     const block = { type: format, children: [] };
//     Transforms.wrapNodes(editor, block);
//   }
// };

// const isBlockActive = (editor, format, blockType = 'type') => {
//   const { selection } = editor;
//   if (!selection) return false;

//   const [match] = Array.from(
//     Editor.nodes(editor, {
//       at: Editor.unhangRange(editor, selection),
//       match: n =>
//         !Editor.isEditor(n) &&
//         SlateElement.isElement(n) &&
//         n[blockType] === format,
//     })
//   );

//   return !!match;
// };

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>;
  if (leaf.code) children = <code>{children}</code>;
  if (leaf.italic) children = <em>{children}</em>;
  if (leaf.underline) children = <u>{children}</u>;
  return <span {...attributes}>{children}</span>;
};

// const BlockButton = ({ format, icon }) => {
//   const editor = useSlate();
//   return (
//     <Button
//       active={isBlockActive(
//         editor,
//         format,
//         TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
//       )}
//       onMouseDown={event => {
//         event.preventDefault();
//         toggleBlock(editor, format);
//       }}
//     >
//       <Icon>{icon}</Icon>
//     </Button>
//   );
// };

export default function RichTextEditor({
  content,
  contentFooter,
  // contentHeader,
  readOnly,
  saveContent = undefined,
  handleAttachment = undefined,
  imageUrl = undefined,
  appendContent = false,
}) {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.contexto);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const [editor] = useState(() => withReact(createEditor()));
  const imageUrlRef = useRef(imageUrl);
  const menuItemsRef = useRef('');
  const { body } = new DOMParser().parseFromString(content, 'text/html');
  const initialValue = Array.from(body?.childNodes).map(deserialize);
  const [value, setValue] = useState(initialValue);
  const [activeMarks, setActiveMarks] = useState({});

  // Need this to remove content from previous editor when opening new report
  useEffect(() => {
    if (!appendContent) {
      const { body } = new DOMParser().parseFromString(content, 'text/html');
      const newValue = Array.from(body.childNodes).map(deserialize);

      Transforms.deselect(editor);

      // Clear the editor.
      if (editor.children.length > 0) {
        Editor.withoutNormalizing(editor, () => (editor.children = []));
      }

      Transforms.insertNodes(editor, newValue);

      // Reset the selection to the start of the document.
      if (editor.children.length > 0) {
        Transforms.select(editor, Editor.start(editor, []));
      } else {
        Transforms.insertNodes(editor, {
          type: 'paragraph',
          children: [{ text: '' }],
        });
      }
    }
  }, [content]);

  // Need this to add the content from Menu items
  useEffect(() => {
    if (appendContent) {
      // Parse the new content into a DOM.
      const { body } = new DOMParser().parseFromString(content, 'text/html');
      const nodes = Array.from(body.childNodes).map(deserialize);
      const textNodes = nodes.filter(node => 'text' in node);
      const newText = textNodes.map(node => node.text).join('');

      // Determine what text hasn't been inserted before.
      const itemToInsert = newText.replace(menuItemsRef.current, '');

      Transforms.insertText(editor, itemToInsert, {
        at: Editor.end(editor, []),
      });

      // Update the ref with the newly inserted text.
      menuItemsRef.current = newText;
    }
  }, [content, appendContent]);

  useEffect(() => {
    imageUrlRef.current = imageUrl;
  }, [imageUrl]);

  const toggleMark = (editor, format) => {
    const marks = Editor.marks(editor);
    const isActive = marks ? marks[format] === true : false;

    if (isActive) {
      Editor.removeMark(editor, format);
      setActiveMarks({ ...activeMarks, [format]: false });
    } else {
      Editor.addMark(editor, format, true);
      setActiveMarks({ ...activeMarks, [format]: true });
    }
  };

  const handleSave = () => {
    saveContent(serialize(value));
  };

  const handlePrint = e => {
    dispatch(loadingOpen());
    e.preventDefault();

    const iframe = document.createElement('iframe');
    const img = new Image();
    let content = value.map(extractText).join('');

    iframe.style.visibility = 'hidden';
    document.body.appendChild(iframe);

    if (imageUrlRef.current) {
      img.src = imageUrlRef.current;

      /* img.onload = () => {
        content = `<div style="display: flex; margin-top: 10px; flex-direction: column; align-items: center; box-sizing: border-box; max-width: 100%; max-height: 100vh;">
      <div style="display: flex; justify-content: center; align-items: center;">
        <img src="${img.src}" alt="" style="max-width: 85%; max-height: 85%;" />
      </div>
      <div style="width: 100%; text-align: justify; text-justify: inter-word;">
        ${content}
      </div>
      <footer style="width: 100%; display: flex; justify-content: center; align-items: center;">
        ${contentFooter}
      </footer>
    </div>`; */

      img.onload = () => {
        content = `
        <div style="margin-top: 80px;">

<div align="center">
    <!-- nomeIMG será substituído pelo caminho da imagem -->
    <img src="${img.src}" alt="ECG Imagem" width="720" height="800" />
</div>
<br>
<!-- DMRec.QECGObservacao.value será substituído pelo texto da observação -->
${content}
<br></br>
 ${contentFooter}

        </div>
        `;

        // Ensure iframe exists and is correctly referenced
        if (!iframe) {
          console.error('Iframe is not defined');
          return;
        }

        // Check if iframe is correctly attached to the DOM
        if (!document.body.contains(iframe)) {
          console.error('Iframe is not attached to the DOM');
          return;
        }

        iframe.contentDocument.open();
        iframe.contentDocument.write(content);
        iframe.contentDocument.close();

        // Add a delay to ensure content is rendered before printing
        setTimeout(() => {
          iframe.contentWindow.print();
          iframe.parentNode.removeChild(iframe);
          dispatch(loadingClose());
        }, 500);
      };
    } else {
      console.log('imageUrl prop is empty!');
      iframe.contentDocument.open();
      iframe.contentDocument.write(content);
      iframe.contentDocument.close();
      iframe.contentWindow.print();
      iframe.parentNode.removeChild(iframe);
    }
  };

  const triggerEvent = window.matchMedia('(hover: hover)').matches
    ? ['hover', 'focus']
    : ['focus'];

  return (
    <>
      <Slate
        editor={editor}
        initialValue={value}
        onChange={value => setValue(value)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
            margin: '10px',
            padding: '10px',
            border: '1px solid #ccc',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
          }}
        >
          <OverlayTrigger
            trigger={triggerEvent}
            overlay={<Tooltip id="tooltip-bold">Negrito</Tooltip>}
          >
            <FaBold
              cursor="pointer"
              onClick={event => {
                event.preventDefault();
                toggleMark(editor, 'bold');
                ReactEditor.focus(editor);
              }}
              style={{ color: activeMarks['bold'] ? 'black' : '#ccc' }}
            />
          </OverlayTrigger>
          <OverlayTrigger
            trigger={triggerEvent}
            overlay={<Tooltip id="tooltip-italic">Itálico</Tooltip>}
          >
            <FaItalic
              cursor="pointer"
              onClick={event => {
                event.preventDefault();
                toggleMark(editor, 'italic');
                ReactEditor.focus(editor);
              }}
              style={{ color: activeMarks['italic'] ? 'black' : '#ccc' }}
            />
          </OverlayTrigger>
          <OverlayTrigger
            trigger={triggerEvent}
            overlay={<Tooltip id="tooltip-underline">Underline</Tooltip>}
          >
            <FaUnderline
              cursor="pointer"
              onClick={event => {
                event.preventDefault();
                toggleMark(editor, 'underline');
                ReactEditor.focus(editor);
              }}
              style={{ color: activeMarks['underline'] ? 'black' : '#ccc' }}
            />
          </OverlayTrigger>
          {handleAttachment && (
            <Button onClick={handleAttachment} variant="warning" size="sm">
              Anexar
            </Button>
          )}
          <Button onClick={handlePrint} size="sm">
            Imprimir
          </Button>
          {!readOnly && (
            <Button onClick={handleSave} variant="success" size="sm">
              Salvar
            </Button>
          )}

          {/* <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <BlockButton format="left" icon="format_align_left" />
          <BlockButton format="center" icon="format_align_center" />
          <BlockButton format="right" icon="format_align_right" />
          <BlockButton format="justify" icon="format_align_justify" /> */}
        </div>

        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly={readOnly}
          autoFocus
          style={{
            padding: '10px',
            margin: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            minHeight: '200px',
            maxHeight: '500px',
            overflowY: 'auto',
          }}
        />
      </Slate>

      {loading && <Loading />}
    </>
  );
}
